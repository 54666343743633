<template>
  <b-modal
    :id="'modal-tournament-win' + updatedTournamentWin.id"
    :hide-footer="true"
    modal-class="modal-tournament-win bg-img"
    @hidden="removeTournament()"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close"> </a>
      <div
        id="header-logo"
        class="bg-img to-center-abs h-100"
        :class="$store.state.locale"
      ></div>
    </template>

    <template v-if="updatedTournamentWin" slot="default">
      <div
        class="w-100 d-flex flex-column align-items-center"
        style="margin-top: -50px"
      >
        <div class="slot-img-field" @click="handleSlotImgClick">
          <img draggable="false" :src="imgPath" class="w-100 h-100" />
        </div>
        <span
          class="text-uppercase bold light-yellow-shadow"
          style="font-size: 26px"
        >
          {{ tourneyDateString }}
        </span>
        <span
          class="text-uppercase bold text-white red-shadow mt-3"
          style="font-size: 18px"
        >
          {{ $t("tournaments.info.reward") }}
        </span>
        <div class="d-inline-flex align-items-center justify-content-center">
          <span
            class="text-uppercase bold text-white red-shadow simple-line"
            style="font-size: 56px"
          >
            {{ goldWon }}</span
          >
          <div class="gold-coin bg-img"></div>
        </div>
        <div
          v-if="crownsWon"
          class="d-inline-flex align-items-center justify-content-center"
        >
          <span
            class="text-uppercase bold text-white red-shadow simple-line"
            style="font-size: 40px"
          >
            {{ crownsWon }}</span
          >
          <div class="crown bg-img"></div>
        </div>
        <span
          class="text-uppercase bold text-white red-shadow"
          :class="crownsWon ? '' : 'mt-3'"
          style="font-size: 16px"
        >
          {{ $t("tournaments.info.your_placement") }} </span
        ><span
          class="text-uppercase bold text-white red-shadow simple-line"
          style="font-size: 36px"
        >
          {{
            $t("tournaments.history.info.rank", {
              0: updatedTournamentWin.rank,
            })
          }}
        </span>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ModalTournamentWin",

  props: { updatedTournamentWin: { type: Object, default: null } },
  data() {
    return {
      data: null,
      // updatedTournamentWin: {
      //   id: 2801,
      //   user_id: 222,
      //   tournament_history_id: 1350305,
      //   amount: 6200000,
      //   rank: 1,
      //   gold_amount: 141210,
      //   crown_amount: 15,
      //   loyalty_amount: 12,
      //   game_id: 295,
      //   type: "TOTAL_WIN_LIMIT",
      //   limit: 5000000,
      //   image_path:
      //     "https://gf7-mk-dev-storage.s3.eu-central-1.amazonaws.com/uploads/games/2379bf9190_wildblood.jpg",
      //   start_date: "2022-04-06T09:30:15.000000Z",
      //   end_date: "2022-04-06T09:50:15.000000Z",
      //   tournament_amount: 2825000,
      //   ranking: {
      //     scoreboard: [
      //       {
      //         user_id: 222,
      //         name: "uweBoll",
      //         avatar_url:
      //           "https://gf7-mk-dev-storage.s3.eu-central-1.amazonaws.com/avatar/princess.png",
      //         amount: 6200000,
      //         reward: 141210,
      //         rank: 1,
      //       },
      //     ],
      //   },
      // },
    };
  },

  computed: {
    gold: function () {
      if (this.updatedTournamentWin === null || !this.updatedTournamentWin) {
        return;
      }
      return this.formatNumber(this.updatedTournamentWin.gold_amount);
    },
    crowns: function () {
      if (this.updatedTournamentWin === null || !this.updatedTournamentWin) {
        return;
      }
      return this.updatedTournamentWin.crown_amount;
    },
    loyalty: function () {
      if (this.updatedTournamentWin === null || !this.updatedTournamentWin) {
        return;
      }
      return this.updatedTournamentWin.loyalty_amount;
    },

    dataGames: function () {
      return this.$store.getters["gameplay/getGames"];
    },
    imgPath() {
      if (!this.updatedTournamentWin.image_path)
        return require("@/assets/img/game/placeholder.jpg");
      return this.updatedTournamentWin.image_path;
    },
    tourneyDateString() {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };

      return (
        this.$t("tournaments.history.info.datetime2") +
        " " +
        new Date(this.updatedTournamentWin.end_date).toLocaleDateString(
          this.$store.state.locale,
          options
        )
      );
    },
    goldWon() {
      return this.formatNumber(this.updatedTournamentWin.gold_amount);
    },
    crownsWon() {
      if (!this.updatedTournamentWin.crown_amount) return null;
      return this.formatNumber(this.updatedTournamentWin.crown_amount);
    },
  },
  watch: {
    updatedTournament: {
      handler(data) {
        if (!data) {
          return;
        }
        this.data = data;
        this.$store.commit("tournaments/setTournamentUpdateWin", null);
      },
    },
  },

  mounted: function () {
    this.$bvModal.show("modal-tournament-win" + this.updatedTournamentWin.id);
  },

  methods: {
    removeTournament: function () {
      this.$store.commit(
        "tournaments/removeTournamentUpdateWin",
        this.updatedTournamentWin.id
      );
    },
    handleSlotImgClick() {
      this.$bvModal.hide("modal-tournament-win" + this.updatedTournamentWin.id);
      this.$router.push("/tournament/history");
    },
  },
};
</script>
<style lang="scss">
$modal-width: 640px;
$modal-height: 800px;

.modal-tournament-win {
  .modal-dialog {
    max-width: $modal-width;
    height: $modal-height !important;
    background: url(~@/assets/img/tournament/tourney-overlay-win-bg.png)
      center/100% 100% no-repeat;
    .modal-content {
      background-color: transparent;
      border: none;
      .modal-header {
        border: none;
        height: 291px;
        position: relative;
        .close-button {
          right: 0;
          top: 50px;
        }
        #header-logo {
          width: 497px;
          &.de {
            background-image: url(~@/assets/img/tournament/tourney-overlay-head-de.png);
          }
          &.en {
            background-image: url(~@/assets/img/tournament/tourney-overlay-head-eng.png);
          }
        }
      }
      .slot-img-field {
        width: 160px;
        height: 103px;
        &:hover {
          cursor: pointer;
          box-shadow: 0 0 26px #4f0a00, 0 0 20px #4f0a00, 0 0 16px #ff0000,
            0 0 11px #ff0000, 0 0 11px #ff0000, 0 0 5px #ff0000, 0 0 5px #ff0000,
            0 0 5px #ff0000;
        }
      }
      .light-yellow-shadow {
        color: #fff8b7;
        text-shadow: 0 1px 4px black;
      }
      .red-shadow {
        text-shadow: 0 0 26px #4f0a00, 0 0 13px #4f0a00, 0 0 13px #ff0000,
          0 0 6px #ff0000;
      }
      .gold-coin {
        width: 40px;
        height: 40px;
        background-image: url(~@/assets/img/common/coin1.png);
      }
      .crown {
        width: 40px;
        height: 30px;
        background-image: url(~@/assets/img/common/crown4.png);
      }

      .simple-line {
        line-height: 1.1;
      }
    }

    @media (max-height: 750px), (max-width: 1200px) {
      transform: scale(0.8) !important;
      top: -80px !important;
      margin-bottom: -20%;
    }
  }
}
</style>
